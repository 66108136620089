exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konsertit-js": () => import("./../../../src/pages/konsertit.js" /* webpackChunkName: "component---src-pages-konsertit-js" */),
  "component---src-pages-kuulumisia-js": () => import("./../../../src/pages/kuulumisia.js" /* webpackChunkName: "component---src-pages-kuulumisia-js" */),
  "component---src-pages-liity-kuoroon-js": () => import("./../../../src/pages/liity-kuoroon.js" /* webpackChunkName: "component---src-pages-liity-kuoroon-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("./../../../src/pages/ota-yhteytta.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

